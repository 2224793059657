// font family
$titles-font: 'Open Sans', sans-serif;
$text-font: 'Open Sans', sans-serif;

// sizes
$h1-size: 5.2rem;
$h2-size: 4.2rem;
$h3-size: 3.2rem;
$h4-size: 2.2rem;
$p-size: 1.6rem;
$input-size: 1.4rem;
