* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;
  position: relative;
}

.container {
  max-width: 133rem !important;
  margin: 0 auto !important;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #da323f #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #da323f;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #da323f;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #c42430;
}

nav {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(24, 22, 20, 0.8196078431);
  z-index: 99999;
  margin: 0 auto;
}
nav .navbar {
  max-width: 133rem;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
  margin: 0 auto;
}
nav .navbar__img {
  width: 20rem;
}
nav .navbar__img img {
  width: 80%;
  height: 100%;
}
nav .navbar__links {
  display: flex;
  list-style: none;
  gap: 2.1rem;
}
nav .navbar__links a {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
}
nav .navbar__links a:hover {
  color: #da323f;
}
@media (max-width: 1000px) {
  nav .navbar__links {
    display: none;
  }
}
nav .navbar__buttons {
  display: flex;
  gap: 2.5rem;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  align-items: center;
}
@media (max-width: 1000px) {
  nav .navbar__buttons {
    display: none;
  }
}
nav .navbar__buttons__sign-in {
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}
nav .navbar__buttons__sign-in:hover {
  color: #da323f;
}
nav .navbar__buttons__register {
  background-color: rgb(255, 255, 255);
  color: #ffffff;
  padding: 1.5rem 3rem;
  border-radius: 3px;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
  transition: all 0.3s;
}
nav .navbar__buttons__register:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.5);
  background-color: #fa4226;
}
nav .navbar__buttons a {
  text-decoration: none;
}

.mobile-hamb {
  font-size: 2.8rem;
  display: none;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
}
.mobile-hamb:hover {
  color: white;
}
@media (max-width: 1000px) {
  .mobile-hamb {
    display: flex;
  }
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #ffffff;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.mobile-navbar__close {
  font-size: 3rem;
  position: absolute;
  top: 3.5rem;
  right: 3.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.mobile-navbar__close:hover {
  color: #da323f;
}
.mobile-navbar__links {
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: 2.3rem;
  gap: 3rem;
  text-align: center;
}
.mobile-navbar__links li a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  transition: all 0.3s;
}
.mobile-navbar__links li a:hover {
  color: #da323f;
}

.open-nav {
  left: 0;
}

.hero-content {
  width: 100%;
  align-items: center;
  position: relative;
}
@media (max-width: 800px) {
  .hero-content {
    justify-content: center;
    flex-direction: column;
  }
}
.hero-content__text {
  z-index: 3;
  padding: 0 10rem;
  position: absolute;
  align-items: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 30%;
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .hero-content__text {
    text-align: center;
    align-items: center;
    margin-top: 3rem;
    padding: 0 3rem;
    width: 60%;
  }
}
@media (max-width: 800px) {
  .hero-content__text {
    text-align: center;
    align-items: center;
    margin-top: 3rem;
    padding: 0 3rem;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .hero-content__text {
    text-align: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;
  }
}
.hero-content__text h2 {
  font-size: 4.2rem;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  text-align: center;
}
@media (max-width: 450px) {
  .hero-content__text h2 {
    font-size: 2.5rem;
  }
}
.hero-content__text p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  margin: 3rem 0;
  text-align: center;
}
.hero-content__text__btns {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  width: 80%;
}
@media (max-width: 450px) {
  .hero-content__text__btns {
    flex-direction: column;
    width: 100%;
  }
}
.hero-content__text__btns a {
  background-color: #da323f;
  color: #ffffff;
  padding: 1.2rem 4rem;
  border-radius: 50px;
  transition: all 0.3s;
  text-decoration: none;
  text-align: center;
  width: 100%;
}
.hero-content_img {
  flex: 1 0 50%;
  vertical-align: middle;
  text-align: center;
  background: #f7f7f7;
}
.hero-content_img img {
  width: 100%;
  height: 650px;
  -o-object-fit: cover;
     object-fit: cover;
}

.scroll-up {
  position: fixed;
  font-size: 2.5rem;
  color: white;
  background-color: #da323f;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  bottom: 10rem;
  right: 3rem;
  z-index: 20;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
  border-radius: 10px;
}

.show-scroll {
  display: flex;
}

.featured {
  background-color: #ffffff;
  margin-top: 5.3rem;
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 30%;
}
@media (max-width: 800px) {
  .featured {
    background-image: none;
  }
}

.finally-sec {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .finally-sec {
    flex-wrap: wrap;
  }
}
.finally-sec-image {
  width: 50%;
}
@media (max-width: 768px) {
  .finally-sec-image {
    flex: 0 0 100%;
    order: 1;
  }
}
.finally-sec-image img {
  width: 100%;
  /* padding: 0rem 5rem; */
  height: 540px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 768px) {
  .finally-sec-image img {
    height: 100%;
    padding: 0rem 0rem;
  }
}
.finally-sec-text {
  padding: 0 2.5rem;
  width: 90%;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .finally-sec-text {
    flex: 0 0 100%;
    order: 2;
  }
}
.finally-sec-text h2 {
  font-size: 3rem;
  color: #da323f;
  margin-bottom: 3rem;
}
.finally-sec-text p {
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 10px;
}
.finally-sec__name {
  display: flex;
  margin-top: 3rem;
}
.finally-sec__name__profile {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.finally-sec__name__profile-pic {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
}
.finally-sec__name__profile span img {
  width: 18rem;
}
.finally-sec__name__profile span p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  top: -11px;
  position: relative;
}

.featured-container {
  display: flex;
  flex-direction: column;
}
.featured-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
}
.featured-container__title h4 {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
}
.featured-container__section {
  margin: 1rem;
}
@media (max-width: 800px) {
  .featured-container__section {
    padding: 0;
    margin: 2rem 1rem;
  }
}
.featured-container__section__boxes {
  text-align: center;
}
.featured-container__section__boxes img {
  width: 100px;
  margin: 0 10px;
}

.product-section {
  background-color: #ffffff;
  padding: 4rem 0rem;
}
.product-section .product-sec {
  padding: 0 2.5rem;
}
.product-section h4 {
  color: #f48090;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 1px;
}
.product-section h2 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #333181;
}

.prod_box {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: #fff;
}
.prod_box img {
  width: 100%;
}
.prod_box .tagd {
  margin-top: 5px;
}
.prod_box .tagd span {
  background: #f48090;
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  color: #fff;
  font-size: 1.1rem;
}
.prod_box .head {
  margin-top: 1rem;
  font-size: 1.6rem;
}
.prod_box .desc {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.benefits-section {
  background-color: #1e1a61;
  padding: 5.3rem 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 20%;
}
@media (max-width: 800px) {
  .benefits-section {
    background-image: none;
  }
}

.benefits-container {
  display: flex;
  flex-direction: column;
}
.benefits-container .icon {
  font-size: 2.2rem;
  background-position: center;
  background-size: cover;
  transition: linear 0.2s;
  color: #fff;
  background-color: #ff7748;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.2rem 1.8rem;
}
.benefits-container .icon:hover {
  color: #e1e1e1;
  background-color: #3d3d3d;
}
.benefits-container .icon:hover + p {
  visibility: visible;
}
.benefits-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
  width: 60%;
  padding: 0 0 1rem 0;
  border-bottom: 1px solid #f48090;
}
@media (max-width: 800px) {
  .benefits-container__title {
    width: 90%;
  }
}
.benefits-container__title h3 {
  font-weight: 400;
  font-size: 1.3rem;
  color: #f48090;
  letter-spacing: 1px;
}
.benefits-container__title h2 {
  padding: 1rem 0;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #fff;
}
.benefits-container__title p {
  font-size: 1.4rem;
  color: #fff;
  line-height: 1.8;
}
.benefits-container__title button {
  background: none;
  all: unset;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  margin: 3rem 0;
}
.benefits-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 6rem;
  padding: 0 2.5rem;
}
@media (max-width: 1021px) {
  .benefits-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}
@media (max-width: 800px) {
  .benefits-container__boxes {
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
  }
}
.benefits-container__boxes__box {
  text-align: center;
  padding: 1rem 6rem;
}
@media (max-width: 500px) {
  .benefits-container__boxes__box {
    padding: 1rem 1rem;
  }
}
.benefits-container__boxes__box img {
  height: auto;
  width: 90px;
}
.benefits-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  line-height: 1.43;
}

.banner-section {
  width: 100%;
  height: auto;
  background-color: #fff;
  text-align: center;
}
.banner-section img {
  height: 550px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 800px) {
  .banner-section img {
    height: 350px;
  }
}

.banner-content {
  width: 100%;
  align-items: center;
  position: relative;
}
@media (max-width: 800px) {
  .banner-content {
    justify-content: center;
    flex-direction: column;
  }
}
.banner-content__text {
  z-index: 3;
  padding: 2rem 10rem;
  position: absolute;
  align-items: center;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 25%;
  right: 15%;
  margin-top: 1rem;
  border-radius: 1rem;
  background: #2d3191;
}
@media (max-width: 800px) {
  .banner-content__text {
    text-align: center;
    align-items: center;
    margin-top: 3rem;
    padding: 0 3rem;
  }
}
@media (max-width: 450px) {
  .banner-content__text {
    text-align: center;
    align-items: center;
    margin-top: 3rem;
  }
}
.banner-content__text h2 {
  font-size: 4.2rem;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  text-align: center;
}
@media (max-width: 450px) {
  .banner-content__text h2 {
    font-size: 2.5rem;
  }
}
.banner-content__text p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  margin: 2rem 0 0 0;
  text-align: center;
}
.banner-content__text__btns {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  width: 80%;
}
@media (max-width: 450px) {
  .banner-content__text__btns {
    flex-direction: column;
    width: 100%;
  }
}
.banner-content__text__btns a {
  background-color: #3d37a6;
  color: #ffffff;
  padding: 1.2rem 4rem;
  border-radius: 50px;
  transition: all 0.3s;
  text-decoration: none;
  text-align: center;
  width: 100%;
}
.banner-content_img {
  flex: 1 0 50%;
  vertical-align: middle;
  text-align: center;
  background: #f7f7f7;
}
.banner-content_img img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}

.purchase-section {
  width: 100%;
  padding: 3rem 0;
}
.purchase-section .container {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .purchase-section .container {
    justify-content: center;
    text-align: center;
    align-items: center;
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.purchase-section .container .purchase-image {
  position: relative;
}
.purchase-section .container .purchase-image img {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .purchase-section .container .purchase-image {
    text-align: center;
    align-items: center;
  }
}
.purchase-section .container .purchase-text {
  padding: 0 2.5rem;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}
.purchase-section .container .purchase-text h3 {
  font-size: 3.2rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}
.purchase-section .container .purchase-text .step {
  width: 100%;
  float: left;
}
.purchase-section .container .purchase-text .step p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.1rem;
}
.purchase-section .container .purchase-text .step p span {
  font-weight: 700;
  color: #1e1a61;
}
@media screen and (max-width: 800px) {
  .purchase-section .container .purchase-text .step p {
    text-align: left;
  }
}
.purchase-section .container .purchase-text .pricing {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.purchase-section .container .purchase-text .pricing_grand {
  font-size: 3.5rem;
  font-weight: 700;
  color: #2c293b;
  text-align: center;
}
.purchase-section .container .purchase-text .pricing_grand_dec {
  font-size: 1.5rem;
  vertical-align: super;
}
.purchase-section .container .purchase-text .pricing_shipping {
  font-size: 1.4rem;
  color: #2c293b;
  text-align: center;
}
.purchase-section .container .purchase-text .pricing_buy {
  all: unset;
  margin-top: 0.5rem;
  background-color: #3d37a6;
  color: #ffffff;
  padding: 1.2rem 4rem;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
}
.purchase-section .container .purchase-text .pricing_buy:hover {
  background-color: #2d2894;
}
.purchase-section .container .purchase-text .radio-toolbar-package {
  margin-bottom: 1.8rem;
  text-align: left;
}
.purchase-section .container .purchase-text .radio-toolbar-package input[type=radio] {
  display: none;
}
.purchase-section .container .purchase-text .radio-toolbar-package label {
  display: inline-block;
  background-color: #ffffff;
  padding: 1.2rem 3rem;
  min-width: 30%;
  margin: 0 1rem 1rem 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
}
.purchase-section .container .purchase-text .radio-toolbar-package input[type=radio]:checked + label {
  background-color: #1e1a61;
  border: #1e1a61;
  color: #fff;
  transition: 0.2s;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency {
  margin-bottom: 1.8rem;
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label {
  grid-gap: 2rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  gap: 0rem;
  grid-template-columns: 2em auto auto;
  margin: 0 1rem 1rem 0;
  padding: 1.4rem 2rem;
  width: 100%;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #3d37a6;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #b4b4b4;
  border-radius: 50%;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label input[type=radio]:checked {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0.4rem;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #3d37a6;
  width: 1.15em;
  height: 1.15em;
  border: 0.7rem solid #3d37a6;
  border-radius: 50%;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label input[type=radio]:checked::before {
  transform: scale(1);
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label span {
  text-align: right;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency label:has(> input[type=radio]:checked) {
  background-color: #fff;
  border: 2px solid #3d37a6;
  color: #3d37a6;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency .container:hover input ~ .checkmark {
  background-color: #ccc;
}
.purchase-section .container .purchase-text .radio-toolbar-frequency .container input:checked ~ .checkmark {
  background-color: #2196F3;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor {
  margin-bottom: 1.8rem;
  width: 100%;
  display: table;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor label {
  grid-gap: 2rem;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  float: left;
  font-size: 16px;
  font-weight: 600;
  gap: 0rem;
  grid-template-columns: 3.2em auto;
  margin: 0 1rem 1rem 0;
  width: 41%;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor label input[type=radio] {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  border: 0.2rem solid #fff;
  border-radius: 50%;
  width: 4.9rem;
  height: 4.9rem;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor label input[type=radio]:checked {
  box-shadow: 0 0 0 1px #1e1a61;
  border-width: 0.4rem;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor label span {
  margin-left: 1rem;
  padding: 1.3rem 0;
  text-align: left;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor .flav_vanilla input[type=radio] {
  background: #f2ea94;
}
.purchase-section .container .purchase-text .radio-toolbar-flavor .flav_chocolate input[type=radio] {
  background: #925a30;
}
@media screen and (max-width: 1000px) {
  .purchase-section .container .purchase-text {
    text-align: left;
    align-items: left;
    position: relative;
  }
}

footer {
  background-color: #c42430;
  padding: 4rem 2.5rem;
}

.footer-content-3 {
  color: #fff;
  font-size: 1.3rem;
  margin-top: 3.5rem;
}
@media (max-width: 650px) {
  .footer-content-3 {
    text-align: left;
  }
}

.footer-content {
  display: grid;
  color: #ffffff;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  text-align: left;
}
@media (max-width: 1100px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 650px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
.footer-content__1 {
  list-style: none;
  text-decoration: none;
  color: #ffffff;
  transition: all 0.2s;
  font-size: 1.8rem;
  font-size: 1.5rem;
}
@media (max-width: 650px) {
  .footer-content__1 {
    text-align: left;
  }
}
.footer-content__2 {
  transition: all 0.2s;
  font-size: 1.5rem;
  text-align: right;
}
@media (max-width: 650px) {
  .footer-content__2 {
    text-align: left;
  }
}
.footer-content__2 a {
  text-decoration: underline;
  cursor: pointer;
}

.active-modal {
  opacity: 1 !important;
  display: flex !important;
}

.modal-overlay {
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999999;
  top: 0;
  right: 0;
}

.mod-modal {
  opacity: 0;
  display: none;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999999999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55rem;
  height: 85vh;
  border: 2px solid white;
  background-color: rgb(255, 255, 255);
  padding-right: 2px;
  color: #010103;
}
@media (max-width: 800px) {
  .mod-modal {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .mod-modal {
    top: 50%;
  }
}
.mod-modal__title {
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: white;
  color: white;
  align-items: center;
}
.mod-modal__title i {
  font-size: 2.5rem;
  color: rgba(0, 0, 0, 0.919);
  cursor: pointer;
  transition: all 0.2;
  float: right;
}
.mod-modal__title i:hover {
  color: rgb(0, 0, 0);
}
.mod-modal__message {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 4rem;
  padding: 0 1rem;
  background-color: white;
  font-size: 1.3rem;
}

.choose-section {
  background-color: white;
  padding: 2rem 0 10rem 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
}
@media (max-width: 800px) {
  .choose-section {
    background-image: none;
  }
}
.choose-section-img {
  width: 100%;
  height: 500px;
  padding: 3.7rem 2rem;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ff7748;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(rgba(225, 136, 109, 0), rgba(255, 255, 255, 0)), url(../images/chooseUs/main.png);
  background-position-x: center;
  background-position-y: center;
}

.choose-container {
  display: flex;
  flex-direction: column;
}
.choose-container__img {
  width: 90%;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 550px) {
  .choose-container__img {
    width: 100%;
  }
}

.text-container {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
}
@media (max-width: 1000px) {
  .text-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }
}
.text-container__left {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
}
@media (max-width: 1000px) {
  .text-container__left {
    align-items: center;
    text-align: center;
  }
}
.text-container__left h4 {
  font-size: 2.2rem;
  margin-bottom: 0.7rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
.text-container__left h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.text-container__left p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  color: #706f7b;
  margin-bottom: 3.3rem;
}
.text-container__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #da323f;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  transition: all 0.3s;
  border: 2px solid #da323f;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
}
.text-container__left a:hover {
  background-color: #c42430;
}
.text-container__right {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 44rem;
}
.text-container__right__box {
  display: flex;
}
@media (max-width: 550px) {
  .text-container__right__box {
    flex-direction: column;
    align-items: center;
  }
}
.text-container__right__box img {
  width: 11rem;
  height: 11rem;
  margin-right: 1.1rem;
}
.text-container__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}
.text-container__right__box__text h4 {
  font-size: 3.2rem;
}
.text-container__right__box__text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Open Sans", sans-serif;
  line-height: 1.3;
}

.plan-section {
  background-color: #ffffff;
  padding: 5.3rem 0;
  background-image: linear-gradient(rgba(140, 107, 16, 0), rgba(193, 144, 8, 0)), url(../images/plan/coffee.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 60%;
}
@media (max-width: 800px) {
  .plan-section {
    background-image: none;
  }
}

.plan-container {
  display: flex;
  flex-direction: column;
}
.plan-container .icon {
  font-size: 2.2rem;
  background-position: center;
  background-size: cover;
  transition: linear 0.2s;
  color: #fff;
  background-color: #ff7748;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.2rem 1.8rem;
}
.plan-container .icon:hover {
  color: #e1e1e1;
  background-color: #3d3d3d;
}
.plan-container .icon:hover + p {
  visibility: visible;
}
.plan-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
}
.plan-container__title h3 {
  font-size: 3.2rem;
  font-family: "Open Sans", sans-serif;
  color: #da323f;
  font-weight: 500;
}
.plan-container__title h2 {
  font-size: 4.2rem;
  font-family: "Open Sans", sans-serif;
}
.plan-container__title p {
  font-size: 1.6rem;
}
.plan-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 3.7rem;
  padding: 0 3rem;
}
@media (max-width: 1021px) {
  .plan-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}
@media (max-width: 800px) {
  .plan-container__boxes {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}
.plan-container__boxes__box {
  text-align: center;
  padding: 1rem 6rem;
}
@media (max-width: 500px) {
  .plan-container__boxes__box {
    padding: 1rem 1rem;
  }
}
.plan-container__boxes__box img {
  width: 17rem;
  height: auto;
}
.plan-container__boxes__box h3 {
  font-size: 3.2rem;
  margin-bottom: 1rem;
}
.plan-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #706f7b;
  line-height: 1.43;
}

.prod-section {
  background-color: #ffffff;
  padding: 5.3rem 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 60%;
}
@media (max-width: 800px) {
  .prod-section {
    background-image: none;
  }
}

.prod-container {
  display: flex;
  flex-direction: column;
}
.prod-container .icon {
  font-size: 2.2rem;
  background-position: center;
  background-size: cover;
  transition: linear 0.2s;
  color: #fff;
  background-color: #ff7748;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.2rem 1.8rem;
}
.prod-container .icon:hover {
  color: #e1e1e1;
  background-color: #3d3d3d;
}
.prod-container .icon:hover + p {
  visibility: visible;
}
.prod-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
}
.prod-container__title h3 {
  font-size: 3.2rem;
  font-family: "Open Sans", sans-serif;
  color: #da323f;
  font-weight: 500;
}
.prod-container__title h2 {
  font-size: 4.2rem;
  font-family: "Open Sans", sans-serif;
}
.prod-container__title p {
  font-size: 1.6rem;
}
.prod-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 3.7rem;
  padding: 0;
}
@media (max-width: 1021px) {
  .prod-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}
@media (max-width: 800px) {
  .prod-container__boxes {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}
.prod-container__boxes__box {
  text-align: center;
  padding: 1rem 4rem;
}
@media (max-width: 500px) {
  .prod-container__boxes__box {
    padding: 1rem 1rem;
  }
}
.prod-container__boxes__box img {
  width: 100%;
  height: 25rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.prod-container__boxes__box h3 {
  font-size: 3.2rem;
  margin-bottom: 1rem;
}
.prod-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #706f7b;
  line-height: 1.43;
}

.stats-section {
  background-color: #ffffff;
  padding: 5.3rem 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 60%;
}
@media (max-width: 800px) {
  .stats-section {
    background-image: none;
  }
}

.stats-container {
  display: flex;
  flex-direction: column;
}
.stats-container .icon {
  font-size: 2.2rem;
  background-position: center;
  background-size: cover;
  transition: linear 0.2s;
  color: #fff;
  background-color: #ff7748;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.2rem 1.8rem;
}
.stats-container .icon:hover {
  color: #e1e1e1;
  background-color: #3d3d3d;
}
.stats-container .icon:hover + p {
  visibility: visible;
}
.stats-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
}
.stats-container__title h3 {
  font-size: 3.2rem;
  font-family: "Open Sans", sans-serif;
  color: #da323f;
  font-weight: 500;
}
.stats-container__title h2 {
  font-size: 4.2rem;
  font-family: "Open Sans", sans-serif;
}
.stats-container__title p {
  font-size: 1.6rem;
}
.stats-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 3.7rem;
  padding: 0;
}
@media (max-width: 1021px) {
  .stats-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}
@media (max-width: 800px) {
  .stats-container__boxes {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}
.stats-container__boxes__box {
  text-align: center;
  padding: 1rem 4rem;
}
@media (max-width: 500px) {
  .stats-container__boxes__box {
    padding: 1rem 1rem;
  }
}
.stats-container__boxes__box img {
  width: 100%;
  height: 13rem;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
}
.stats-container__boxes__box h3 {
  font-size: 3.2rem;
  margin-bottom: 1rem;
}
.stats-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #706f7b;
  line-height: 1.43;
}

.contact-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin: 0 auto;
  color: #010103;
  padding: 10rem 2rem;
  background-image: url("/src/images/banners/bg-contact.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 950px) {
  .contact-div {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.contact-div__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 41rem;
}
@media (max-width: 950px) {
  .contact-div__text {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
.contact-div__text h2 {
  font-size: 4.2rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}
.contact-div__text p {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #706f7b;
  line-height: 1.6;
  margin-bottom: 2rem;
}
.contact-div__text a {
  text-decoration: none;
  color: #010103;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.2s;
  margin-bottom: 0.5rem;
}
.contact-div__text a:hover {
  color: #da323f;
}
.contact-div__form {
  display: flex;
  flex-direction: column;
}
.contact-div__form form {
  display: flex;
  flex-direction: column;
}
.contact-div__form form label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.contact-div__form form label b {
  color: #da323f;
}
.contact-div__form form input {
  background-color: #f2f2f2;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.3rem;
}
.contact-div__form form textarea {
  background-color: #f2f2f2;
  height: 18rem;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.5rem;
}
.contact-div__form form button {
  background-color: #da323f;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(196, 36, 48, 0.6392156863) 180, 9, 9, 0.35;
  transition: all 0.3s;
  border: 2px solid #da323f;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
}
.contact-div__form form button:hover {
  box-shadow: 0 10px 15px 0 rgba(196, 36, 48, 0.6392156863) 180, 9, 9, 0.6;
  background-color: #c42430;
}/*# sourceMappingURL=styles.css.map */