@use "../global" as *;



.hero-content {
  width: 100%;
  align-items: center;
  position: relative;

  @media (max-width: 800px) {
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    z-index: 3;
    padding: 0 10rem;
    position: absolute;
    align-items: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 30%;
    
    margin-top: 1rem;
    @media (max-width: 1200px) {
      text-align: center;
      align-items: center;
      margin-top: 3rem;
      padding: 0 3rem;
      width: 60%;

    }

    @media (max-width: 800px) {
      text-align: center;
      align-items: center;
      margin-top: 3rem;
      padding: 0 3rem;
      width: 100%;

    }

    @media (max-width: 450px) {
      text-align: center;
      align-items: center;
      margin-top: 3rem;
      width: 100%;

    }


    
    h2 {
      font-size: $h2-size;
      font-family: $text-font;
      color: #ffffff;
      text-align: center;
      @media (max-width: 450px) {
        font-size: 2.5rem;
  
      }
  
     
      
    }


    p {
      font-size: $p-size;
      font-family: $text-font;
      line-height: 1.6;
      color: rgb(255, 255, 255);
      margin: 3rem 0;
      text-align: center;
    }

    &__btns {
      display: flex;
      gap: 2rem;
      font-size: $p-size;
      font-family: $text-font;
      width: 80%;

      @media (max-width: 450px) {
        flex-direction: column;
        width: 100%;

      }

      a {
        background-color: $text-primary;
        color: #ffffff;
        padding: 1.2rem 4rem;
        border-radius: 50px;
        transition: all 0.3s;
        text-decoration: none;
        text-align: center;width: 100%;
      }

      
    }
  }



  &_img {
    flex: 1 0 50%;
    vertical-align: middle;
    text-align: center;
    background: #f7f7f7;


    img {
      width: 100%;
      height: 650px;
      object-fit: cover;

    }
  }
}




// Scroll Bar styles
.scroll-up {
  position: fixed;
  font-size: 2.5rem;
  color: white;
  background-color: $text-primary;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  bottom: 10rem;
  right: 3rem;
  z-index: 20;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
  border-radius: 10px;

}

.show-scroll {
  display: flex;
}
