@use "../global" as *;

.choose-section {
  background-color: white;
  padding: 2rem 0 10rem 0;

  // background-image: linear-gradient(rgba(140, 107, 16, 0.0),rgba(193, 144, 8, 0.0)),url(../images/hero/spice.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;


  @media (max-width: 800px) {
    background-image: none;
  }


  &-img{
    width: 100%;
    height: 500px;
    padding: 3.7rem 2rem;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ff7748;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,.09);
    background-image: linear-gradient(rgba(225, 136, 109, 0),rgba(255, 255, 255, 0)),url(../images/chooseUs/main.png);
    background-position-x: center;
    background-position-y: center;



  }
}



.choose-container {
  display: flex;
  flex-direction: column;

  &__img {
    width: 90%;
    height: auto;
    margin: 0 auto;

    @media (max-width: 550px) {
      width: 100%;
    }
  }
}

.text-container {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }

  &__left {
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    color: $text-black;

    @media (max-width: 1000px) {
      align-items: center;
      text-align: center;
    }

    h4 {
      font-size: $h4-size;
      margin-bottom: 0.7rem;
      font-weight: 600;
      font-family: $text-font;
    }

    h2 {
      font-size: $h2-size;
      line-height: 1.2;
      margin-bottom: 2rem;
    }

    p {
      font-size: $p-size;
      font-family: $text-font;
      line-height: 1.5;
      color: $text-gray;
      margin-bottom: 3.3rem;
    }

    a {
      text-decoration: none;
      color: white;
      font-weight: 700;
      background-color: $text-primary;
      padding: 1.5rem 2.5rem;
      border-radius: 0.3rem;
      transition: all 0.3s;
      border: 2px solid $text-primary;
      font-size: $p-size;
      width: fit-content;

      &:hover {
        background-color: $text-primary-dark;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    max-width: 44rem;

    &__box {
      display: flex;

      @media (max-width: 550px) {
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 11rem;
        height: 11rem;
        margin-right: 1.1rem;
      }

      &__text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        h4 {
          font-size: $h3-size;
        }

        p {
          font-size: $p-size;
          color: $text-gray;
          font-family: $text-font;
          line-height: 1.3;
        }
      }
    }
  }
}
