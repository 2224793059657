@use "../global" as *;

footer {
  background-color: $text-primary-dark;
  padding: 4rem 2.5rem;
}
.footer-content-3{
  color: #fff;
  font-size: 1.3rem;

  margin-top: 3.5rem;
  @media (max-width: 1100px) {
    // padding: 0 1rem ;
  }

  @media (max-width: 650px) {
    // padding: 0 2rem ;
    text-align: left;
  }

}
.footer-content {
  display: grid;
  color: $bg-white;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  text-align: left;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    gap: 1rem;

  }

  &__1 {
    list-style: none;
    // max-width: 30rem;

    @media (max-width: 650px) {
      text-align: left;
    }

   
     
        text-decoration: none;
        color: $bg-white;

        transition: all 0.2s;
        font-size: 1.8rem;

        font-size: 1.5rem;

   
  }
  &__2 {

    transition: all 0.2s;
    font-size: 1.5rem;
    text-align: right;
    @media (max-width: 650px) {
      text-align: left;
    }

    a{
      text-decoration: underline;
      cursor: pointer;
    }


  }
  

}
