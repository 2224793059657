@use "../global" as *;

.purchase-section
{
    width: 100%;
    padding: 3rem 0;

  .container{
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;

    @media screen and (max-width: 800px) {
      justify-content: center;
      text-align: center;
      align-items: center;
      grid-template-columns: 1fr;
      text-align: center;
    }


    .purchase-image{
      position: relative;
      img{
        width: 100%;
        @media screen and (max-width: 800px) {
            // width: 50%;
        }
      }
      
      @media screen and (max-width: 1000px) {
      text-align: center;
      align-items: center;
      }
    }

    .purchase-text{
      padding: 0 2.5rem;
      max-width: 480px;
      width: 100%;
      margin: 0 auto;


            
      h3{
          font-size: $h3-size;
          line-height: 1.4;
          margin-bottom: 1.5rem;
      }

      .step{
          width: 100%;
          float: left;
        p{
          font-size: $p-size;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 1.1rem;

          span{
              font-weight: 700;
              color: #1e1a61;
          }

          @media screen and (max-width: 800px) {
            text-align: left;
          }
        }
      }


      .pricing{
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          &_grand{
              font-size: 3.5rem;
              font-weight: 700;
              color: #2c293b;
              text-align: center;
              
              &_dec{
                  font-size: 1.5rem;
                  vertical-align: super;

              }
          }

          &_shipping{
              font-size: 1.4rem;
              color: #2c293b;
              text-align: center;

          }

          &_buy{
              all: unset;
              margin-top: 0.5rem;
              background-color: #3d37a6;
              color: #ffffff;
              padding: 1.2rem 4rem;
              cursor: pointer;
              border-radius: 50px;
              transition: all 0.3s;
              font-size: 1.6rem;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              text-align: center;

              &:hover{
                  background-color: #2d2894;

              }
          }


      }

      .radio-toolbar-package 
      {
        margin-bottom: 1.8rem;
        text-align: left;
        input[type="radio"] {
          display: none;
        }

        label {
            display: inline-block;
            background-color: #ffffff;
            padding: 1.2rem 3rem;
            min-width: 30%;
            margin: 0 1rem 1rem 0;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            border : 1px solid #cccccc;
            border-radius: 5px;
            cursor: pointer;
          }

          input[type="radio"]:checked+label {
            background-color: #1e1a61;
            border: #1e1a61;
            color: #fff;
            transition: 0.2s;
          }
        }   


        .radio-toolbar-frequency
        {
          margin-bottom: 1.8rem;

          label {
            grid-gap: 2rem;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            display: grid;
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            gap: 0rem;
            grid-template-columns: 2em auto auto;
            margin: 0 1rem 1rem 0;
            padding: 1.4rem 2rem;
            width: 100%;

            
            input[type="radio"] {
                appearance: none;
                background-color: #fff;
                margin: 0;
                font: inherit;
                color: #3d37a6;
                width: 1.15em;
                height: 1.15em;
                border: 0.15em solid #b4b4b4;
                border-radius: 50%;
              }

              input[type="radio"]:checked {
                appearance: none;
                padding: 0.4rem;
                background-color: #fff;
                margin: 0;
                font: inherit;
                color: #3d37a6;
                width: 1.15em;
                height: 1.15em;
                border: 0.7rem solid #3d37a6;
                border-radius: 50%;
              }
              input[type="radio"]::before {
                content: "";
                width: 0.65em;
                height: 0.65em;
                border-radius: 50%;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
              }
              
              input[type="radio"]:checked::before {
                transform: scale(1);
              }
              
              span
              {
                text-align: right;
              }
          }

          label:has(> input[type="radio"]:checked) {
            background-color: #fff;
            border: 2px solid #3d37a6;
            color: #3d37a6;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
              background-color: #ccc;
          }
          
          /* When the radio button is checked, add a blue background */
          .container input:checked ~ .checkmark {
              background-color: #2196F3;
          }   
        }   

              
           
                
        .radio-toolbar-flavor
        {
            margin-bottom: 1.8rem;
                width: 100%;
                display: table;

            label {
 
                  grid-gap: 2rem;
                  background-color: #fff;
                  border-radius: 5px;
                  cursor: pointer;
                  display: grid;
                  float: left;
                  font-size: 16px;
                  font-weight: 600;
                  gap: 0rem;
                  grid-template-columns: 3.2em auto;
                  margin: 0 1rem 1rem 0;
                  width: 41%;

                
                
                input[type="radio"] {
                    
                    appearance: none;
                    -webkit-appearance: none;
                    border: 0.2rem solid #fff;
                    border-radius: 50%;
                    width: 4.9rem;
                    height: 4.9rem;
                  }
                  input[type="radio"]:checked  {
                    box-shadow: 0 0 0 1px #1e1a61;
                    border-width: 0.4rem;
                  }
                  span
                  {
                    margin-left: 1rem;
                    padding: 1.3rem 0;
                    text-align: left;
                  }
              }

              .flav_vanilla{
                input[type="radio"] {
                    background: #f2ea94;
                }
              }
              .flav_chocolate{
                input[type="radio"] {
                    background: #925a30;
                }
              }
            }   

      
      
            @media screen and (max-width: 1000px) {
              text-align: left;
              align-items: left;
              position: relative;
          }
        }


    }

}
