// background colors
$bg-gray: #f7f7f7;
$bg-white: #ffffff;


$white: #ffffff;
$black: #000;



// text color
$text-black: #010103;
$btn-black: #161616;
$text-gray: #706f7b;






// text color
$text-primary: #da323f;
$text-primary-dark: #c42430;
$text-primary-shadow: #c42430a3(180, 9, 9, 0.35);
$text-primary-shadow-dark: #c42430a3(180, 9, 9, 0.6);


$text-secondary: #ffc80d;
$text-secondary-dark: #ce9e01;

