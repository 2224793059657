@use "../global" as *;

.banner-section {
  width: 100%;
  height: auto;
  background-color: #fff;
  text-align: center;
  
  img{    
    height: 550px;
    width: 100%;
    object-fit: cover;
    
    @media (max-width: 800px) {
      height: 350px;
    }
  }

}



.banner-content {
  width: 100%;
  align-items: center;
  position: relative;

  @media (max-width: 800px) {
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    z-index: 3;
    padding: 2rem 10rem;
    position: absolute;
    align-items: center;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 25%;
    right: 15%;
    margin-top: 1rem;
    border-radius: 1rem;
    background: #2d3191;


    @media (max-width: 800px) {
      text-align: center;
      align-items: center;
      margin-top: 3rem;
      padding: 0 3rem;

    }

    @media (max-width: 450px) {
      text-align: center;
      align-items: center;
      margin-top: 3rem;

    }


    
    h2 {
      font-size: $h2-size;
      font-family: $text-font;
      color: #ffffff;
      text-align: center;
      @media (max-width: 450px) {
        font-size: 2.5rem;
  
      }
  
     
      
    }


    p {
      font-size: $p-size;
      font-family: $text-font;
      line-height: 1.6;
      color: rgb(255, 255, 255);
      margin: 2rem 0 0 0;
      text-align: center;
    }

    &__btns {
      display: flex;
      gap: 2rem;
      font-size: $p-size;
      font-family: $text-font;
      width: 80%;

      @media (max-width: 450px) {
        flex-direction: column;
        width: 100%;

      }

      a {
        background-color: #3d37a6;
        color: #ffffff;
        padding: 1.2rem 4rem;
        border-radius: 50px;
        transition: all 0.3s;
        text-decoration: none;
        text-align: center;width: 100%;
      }

      
    }
  }



  &_img {
    flex: 1 0 50%;
    vertical-align: middle;
    text-align: center;
    background: #f7f7f7;


    img {
      width: 100%;
      height: 350px;
      object-fit: cover;

    }
  }
}
