@use "../global" as *;

.stats-section {
  background-color: $bg-white;
  padding: 5.3rem 0;

  // background-image: linear-gradient(rgba(140, 107, 16, 0.0),rgba(193, 144, 8, 0.0)),url(../images/stats/coffee.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 60%;
  
  @media (max-width: 800px) {
    background-image: none;
  }

}

.stats-container {
  display: flex;
  flex-direction: column;

  .icon {
    font-size: 2.2rem;
    background-position: center;
    background-size: cover;
    transition: linear .2s; 
    color: #fff;
    background-color: #ff7748;
    border-radius:100%;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    padding: 1.2rem 1.8rem;

  &:hover{
    color: #e1e1e1;
    background-color: #3d3d3d;

    }
    &:hover + p{
      visibility: visible;

    }
  }

  &__title {
    margin: 0 auto;
    text-align: center;
    color: $text-black;

    h3 {
      font-size: $h3-size;
      font-family: $text-font;
      color: $text-primary;
      font-weight: 500;
    }

    h2 {
      font-size: $h2-size;
      font-family: $titles-font;
      
    }

    p{
      font-size: $p-size;
      
    }
  }

  &__boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-template-rows: auto;
    margin-top: 3.7rem;
    padding: 0;

    @media (max-width: 1021px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 2rem;
    }

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      margin-top: 1rem;
    }

    &__box {
      text-align: center;

      padding: 1rem 4rem;

      @media (max-width: 500px) {
        padding: 1rem 1rem;
      }

      img {
        width: 100%;
        height: 13rem;
        object-fit: contain;
        border-radius: 10px;
      }

      h3 {
        font-size: $h3-size;
        margin-bottom: 1rem;
      }

      p {
        font-size: $p-size;
        font-family: $text-font;
        color: $text-gray;
        line-height: 1.43;
      }
    }
  }
}
